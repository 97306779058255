<template>
  <v-app
    class="bg-grey-lighten-4"
  >
    <v-main>
      <slot />
      <general-snackbar />
    </v-main>
  </v-app>
</template>

<style>
html {
  background-color: #F5F5F5 !important;
}

.v-messages__message {
  text-align: left !important;
  margin-bottom: 1em !important;
}
</style>
