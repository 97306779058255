<template>
<v-snackbar
 v-model="open" 
:color="snackbarContent.length != 0 ? snackbarContent[0].type.toString() : 'black'"
location="bottom"
timeout="2000">
    {{ snackbarContent.length != 0 ? snackbarContent[0].text : '' }}

    <template v-slot:actions>
        <v-btn variant="text" @click="open = false">
            x
        </v-btn>
    </template>
</v-snackbar>   
</template>

<script setup lang="ts">
import {
    useSnackbarStore
} from '~/stores/snackbar';

const snackbarStore = useSnackbarStore()
const {
    open,
    snackbarContent
} = storeToRefs(snackbarStore)




watch(open, (_) => {
    if (!open.value && snackbarContent.value.length != 0) {
        snackbarContent.value.shift()
        if (snackbarContent.value.length != 0) {
        open.value = true
        }
    }
})


</script>

<style scoped>
a:hover,
a:visited,
a:link,
a:active {
    text-decoration: none;
    color: black;
}
</style>
